.searchPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../assets/images/Welcome.png');
    background-size: cover;
    background-position: center;
}

.searchPanel .searchPanelContent {
    background-color: rgba(255, 255, 255, 0.75);
    padding: 2rem;
    border-radius: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.searchPanel .submitButtonStyle {
    background-color: #5783db;
    width: 25%;
    font-size: 1.5rem;
    color: white;
    border: none;
    padding: 1rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 2rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Add stronger shadow on hover */
}

.searchPanel .submitButtonStyle:hover {
    background-color: #4681f4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Add stronger shadow on hover */
}

.searchPanel .orPanel {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.searchPanel .loginButtonStyle {
    background-color: red;
    font-size: 1rem;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Add stronger shadow on hover */
}

.searchPanel .registerButtonStyle {
    background-color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    color: black;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Add stronger shadow on hover */
}

.searchPanel .businessRegButtonStyle {
    margin-top: 1rem;
    background-color: #20B2AA;
    font-size: 1rem;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Add stronger shadow on hover */
}

.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
}

.mainlogo {
    width: 25rem;
    height: auto;
    object-fit: contain;
    position: absolute;
    top: 0rem; /* Adjust the top position as needed */
    left: 1.5rem; /* Adjust the right position as needed */
    padding: 5px; /* Optional: Add padding inside the border */
    z-index: 1000; /* Ensure the logo is on top of other elements */
}

.image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.image-preview-thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
}

.fixed-text {
    font-family: 'Radley', serif;
    position: fixed;
    bottom: 2.5rem;
    right: 20px;
    font-size: 4.1rem;
    width: 35%;
    font-weight: bold;
    text-align: left;
    line-height: 0.9;
    margin: 0;
    letter-spacing: 0.03em; /* Add letter spacing */
}

.fixed-text span {
    display: block;
    margin: 0;
}



/** Mobile **/
@media (max-width: 768px) {
    .searchPanel .searchPanelContent {
        width: 80%;
    }

    .searchPanel .submitButtonStyle {
        width: 50%;
        font-size: 1rem;
    }

    .searchPanel .searchPanelContent h1 {
        font-size: 2vh;
    }

    .fixed-text {
        display: none;
    }

    .mainlogo {
        width: 10rem;
        left: 0.5rem;
    }
}