.formPanel {
    background-color: rgba(255, 255, 255, 0.9);;
    padding: 2.5rem;
    border-radius: 2rem;
    border: 5px solid black;
    width: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formPanel .image {
    width: 10rem;
}

.formPanel .needPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.formPanel .needPanel .needTitle {
    font-size: 1.5rem;
    font-weight: bolder;
    color: black;
    margin: 0rem 0 1rem 0;
    text-align: center;
}

.formPanel .needPanel .needResponse {
    font-size: 1rem;
    color: rgba(10, 10, 10, 1);
    margin: 0rem 0 1rem 0;
}

.formPanel .title {
    font-size: 2rem;
    color: black;
    font-weight: bolder;
}

.formPanel .subTitle {
    font-size: 2rem;
    font-weight: bolder;
    color: black;
    margin: 0rem 0 1rem 0;
}



.formPanel .inputBox {
    margin-top: 1vw;
    margin-bottom: 1vw;
    text-align: left;
}

.formPanel .input {
    width: 45rem;
}

.formPanel .buttonDiv {
    margin-top: 2vw;
    margin-bottom: 1vw;
}

.formPanel .button {
    color: white;
    width: 25vw;
    border: none;
    background-color: #1c77ac;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.3vw;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Add stronger shadow on hover */
}

.formPanel .button:hover {
    background-color: #4d4dff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Add stronger shadow on hover */
}

/* For Business Panel */
.formPanel .financialsPanel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.formPanel .financialsPanel .input {
    width: 100%;
}

.profileImagePanel {
    display: flex;
    flex-direction: column;
}

/* Profile Image */
.profileImage {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
    display: block;
    margin-bottom: 1rem;
}

/* Profile Image */
.profileImagePanel .profileText {
    margin: 0 auto;
    color: black;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}

.main .textPanel {
    background-color: #EDF7F6;
    padding: 2.5rem;
}

.main h2 {
    font-size: 1.5rem;
    font-weight: bolder;
    color: black;
    margin: 0rem 0 1rem 0;
}

.main h3 {
    font-size: 1rem;
    color: black;
    margin: 0rem 0 1rem 0;
}

.main .buttonDiv {
    margin-top: 2vw;
    margin-bottom: 1vw;
    display: flex;
    justify-content: center;
}

.main .button {
    color: white;
    width: 15vw;
    border: none;
    background-color: #1c77ac;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.3vw;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Add stronger shadow on hover */
}

.main .button:hover {
    background-color: #4d4dff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Add stronger shadow on hover */
}

.largeCheckbox {
    width: 1.5rem; /* Set the desired width */
    height: 1.5rem; /* Set the desired height */
}

.custom-swal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* Mobile */
@media only screen and (max-width: 700px) {
    .formPanel {
        width: 90%;
    }

    .formPanel .inputBox {
        width: 100%;
    }

    .formPanel .input {
        width: 100%;
    }

    .formPanel .button {
        font-size: 2rem;
        padding: 1rem;
        width: 100%;
    }

    .formPanel .buttonDiv {
        margin-top: 1vw;
        margin-bottom: 1vw;
        width: 50%;
    }

    .formPanel .financialsPanel {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }

    .formPanel .financialsPanel .input {
        width: 100%;
    }

    .profileImage {
        width: 10vw;
        height: 10vw;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 auto;
        display: block;
        margin-bottom: 1rem;
    }

    .profileImagePanel .profileText {
        margin: 0 auto;
        color: black;
    }

    .formPanel .subTitle {
        font-size: 0.9rem;
    }

    .main .button {
        font-size: 1.5rem;
        padding: 1rem;
        width: 100%;
    }

}