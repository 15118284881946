.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;

    /* Add any other styles you need */
}

.container .leftDashboard {
    width: 15%;
    height: 100%;
    padding-top: 0.5rem;
}

/** Menu **/
.container .leftDashboard .menu {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.container .leftDashboard .menu .menu-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 0.5rem;
    margin-left: 10%;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: bold;
}

.container .leftDashboard .menu .menu-item1 {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 0.5rem;
    margin-left: 10%;
    font-size: 0.9rem;
    cursor: pointer;
    font-weight: bold;
}

/** Right Dashboard **/
.container .rightDashboard {
    position: fixed;
    right: 0;
    top: 0;
    width: 80%;
    height: 100%;
    overflow-x: hidden;
    padding-top: 20px;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5); /* Add left shadow border */
}

.container .rightDashboard .servicesPanel {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.container .rightDashboard .servicesPanel h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.container .rightDashboard .servicesPanel .cardPanel {
    padding: 1rem;
    margin: 1rem;
    box-shadow: 1px 4px 10px rba(0 0, 0, 0.9);
    width: 50%;
    position: relative; /* Ensure the card is the positioning context */
    flex-grow: 1;
}

.container .rightDashboard .servicesPanel .cardPanel .createdAt {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 0.875rem; /* Adjust font size as needed */
    color: #666; /* Adjust color as needed */
}

.cancelButton {
    margin-left: 1rem; /* Add spacing between the card and the button */
}

.cardContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; /* Add spacing between cards */
}

.centeredInputLabel {
    text-align: center; /* Center the text within the label */
    display: block; /* Ensure the label takes up the full width */
    width: 100%; /* Ensure the label takes up the full width */
}

.wideTextarea {
    width: 80%; /* Make the textarea take up the full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.urgencyToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem; /* Add some margin for spacing */
}

.urgencyToggle label {
    margin-right: 0.5rem; /* Add some space between the label and the checkbox */
}

.largeCheckbox {
    width: 1.5rem; /* Set the desired width */
    height: 1.5rem; /* Set the desired height */
}

.descriptionText {
    padding-right: 1rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .container .leftDashboard {
        width: 100%;
    }

    .container .rightDashboard {
        width: 100%;
        position: relative;
        box-shadow: none; /* Remove the shadow border */
    }

    .container .rightDashboard .servicesPanel .cardPanel {
        width: 100%;
    }

    .container .rightDashboard .servicesPanel h2 {
        font-size: 5vw;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .container .rightDashboard .servicesPanel .cardPanel .createdAt {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        font-size: 0.5rem; /* Adjust font size as needed */
        color: #666; /* Adjust color as needed */
    }

    .servicesHeader {
        display: flex;
        flex-direction: row;
        padding: 10px;
        background-color: #5783db; /* Adjust as needed */
    }

    .hamburgerMenu {
        display: flex;
        align-items: center; /* Center vertically */
        cursor: pointer;
    }
    
   .mobileMenu {
        position: relative;
        top: 1vh; /* Adjust based on your header height */
        left: 0;
        width: 100%;
        background-color: #fff; /* Adjust as needed */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }
    
   .mobileMenu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .mobileMenu li {
        padding: 10px;
        border-bottom: 1px solid #ddd; /* Adjust as needed */
    }

    .servicesHeader h2 {
        font-size: 1.5rem;
        padding-left: 10vw;
        color: white;
    }

    .cardContainer {
        margin-bottom: 0.5rem; /* Add spacing between cards */
        flex-direction: column;
    }

    .container .rightDashboard .servicesPanel .cardPanel {    
        width: 100%;
    }

    .css-1peln0i-MuiPaper-root-MuiCard-root {
        max-width: 80% !important;
    }

    .MuiTypography-body2 {
        word-wrap: break-word !important; /* Enable text wrapping */

    }
}