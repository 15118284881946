.logo {
  width: 15rem;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 0;
  right: 0;
}

.custom-hr {
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .logo {
    width: 10vh;
  }

  .tos-checkbox {
    margin-top: 1rem;
  }
}